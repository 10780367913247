import { CommonView } from '../shared/util/common-view.util';
import { Component, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { CarriersService } from '../carriers/carriers.service';
import { CarrierProductsService } from './carrier-products.service';
import { DxLookupDataSourceOptions, DxRowUpdateEvent, DxUtil } from '../shared/util/dx.util';
import { CarrierProduct } from '../shared/models/carrier-product.model';
import { Privilege } from '../shared/models/privilege.enum';
import { CountriesService } from '../countries/countries.service';
import { Country } from '../shared/models/country.model';
import { DxFilter } from '@shared-components/shopsys-commons-ui';
import { Carrier } from '../shared/models/carrier.model';

@Component({
    selector: 'kep-carrier-products',
    templateUrl: './carrier-products.component.html',
})
export class CarrierProductsComponent extends CommonView implements OnInit {

    filter: any;

    Privilege = Privilege;

    dataSource: DataSource;

    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];

    carrierStore: CustomStore;
    countriesStore: CustomStore;
    forwardDeliveryOptionsStore: CustomStore;
    returnDeliveryOptionsStore: CustomStore;

    selectedCountries = [];
    selectedCarriers = [];
    kepCarrierIdEditor: any;
    carrierIdEditor: any;
    selectedCarrierId: String;

    resetCarrierCell = DxUtil.hookSetCellValue<CarrierProduct>(rowData => {
        rowData.carrierId = null;
    });

    resetKepId = DxUtil.hookSetCellValue<CarrierProduct>(rowData => {
        rowData.kepCarrierId = null;
    });

    constructor(
        private carrierProductsService: CarrierProductsService,
        private carrierService: CarriersService,
        private countriesService: CountriesService,
    ) {
        super();
     }

    resetFilter(): void {
        super.resetFilter();

        this.selectedCountries = [];
        this.selectedCarriers = [];
    }

    ngOnInit() {
        this.dataSource = this.carrierProductsService.getCarrierProducts();
        this.carrierStore = this.carrierService.getCarriersAsCustomStore();
        this.countriesStore = this.countriesService.getCountriesAsCustomStore();
        this.forwardDeliveryOptionsStore = this.carrierProductsService.getForwardDeliveryOptions();
        this.returnDeliveryOptionsStore = this.carrierProductsService.getReturnDeliveryOptions();
    }

    onInitNewRow(e) {
        if (this.currentRowData === null) {
            e.data = {
                ageVerification: false,
                cashOnDelivery: false,
                forwardShipment: false,
                returnShipment: false,
                multiPackage: false,
            };
        } else {
            e.data = this.currentRowData;
        }
    }

    onEditorPreparing(e) {
        e.editorOptions.onValueChanged = data => {
            e.setValue(data.value);
            this.isDirty = true;
        };

        if (e.parentType === 'dataRow' && e.dataField === 'kepCarrierId') {
            this.kepCarrierIdEditor = e;
        }

        if (e.parentType === 'dataRow' && e.dataField === 'carrierId') {
            this.carrierIdEditor = e;
            e.editorOptions.onValueChanged = data => {
                e.setValue(data.value);
                this.isDirty = true;
                this.selectedCarrierId = data.value;
                this.updateKepCarrierId();
            };
        }
    }

    onRowUpdate(e: DxRowUpdateEvent<CarrierProduct>) {
        DxUtil.fillUpNewDataOnUpdate(e);
    }

    getFilteredCarriers = (options: DxLookupDataSourceOptions<CarrierProduct>) => {
        if (options.data == null) {
            return { store: this.carrierStore };
        }

        const country = DxUtil.fetchLookupObject<CarrierProduct, Country>(options, 'countryId');

        const filter = DxFilter.equals('country.id', country ? country.id : null);

        return {
            store: this.carrierStore,
            filter,
        };
    };

    getFilteredKepId = (options: DxLookupDataSourceOptions<CarrierProduct>) => {
        if (options.data == null) {
            return { };
        }

        const carrier = DxUtil.fetchLookupObject<CarrierProduct, Carrier>(options, 'carrierId');

        const filter = DxFilter.equals('id', carrier ? carrier.id : null);

        return {
            store: this.carrierStore,
            filter,
        };
    };

    calculateCarrierFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrier.id', filterOperation, filterValue];
    }

    calculateCountryFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['country.id', filterOperation, filterValue];
    }

    validateForwardDeliveryOption(options: { value: string; data: CarrierProduct }): boolean {
        return options.data.forwardShipment === false || options.value != null;
    }

    validateReturnDeliveryOption(options: { value: string; data: CarrierProduct }): boolean {
        return options.data.returnShipment === false || options.value != null;
    }

    millimeterToCentimeter(rowData: CarrierProduct) {
        const column = this as any;

        if (this.currentRowData !== null) {
            return rowData[column.dataField] / 10;
        }
        return rowData[column.dataField];
    }

    gramToKilogram(rowData: CarrierProduct) {
        const column = this as any;

        if (this.currentRowData !== null) {
            if (rowData.maxGrossWeight !== null && rowData.maxGrossWeight !== 0) {
                return rowData[column.dataField] / 1000.0;
            }
        }
        return rowData[column.dataField];
    }

    calculateCellValue(rowData: CarrierProduct) {
        return rowData.kepCarrierId;
    }

    onFilterChange() {
        super.resetFilter();

        this.dataGrid.instance.columnOption('countryId', 'filterValues', this.selectedCountries);
        this.dataGrid.instance.columnOption('carrierId', 'filterValues', this.selectedCarriers);
    }

    updateKepCarrierId() {
        if (this.selectedCarrierId != null) {
            const carrier = this.carrierIdEditor.lookup.items
                .filter(carrierItem => carrierItem.id === this.selectedCarrierId)[0];
            this.kepCarrierIdEditor.setValue(carrier.kepId);
        }
    }
}
