import { Route } from '@angular/router';

import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { ParcelCostComponent } from './parcel-cost.component';

export const PARCEL_COST_ROUTE: Route = {
    path: 'parcel-cost',
    component: ParcelCostComponent,
    data: {
        roles: [],
        pageTitle: 'parcelCosts.title',
    },
    canActivate: [EciAuthGuard],
};
