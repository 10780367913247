import home from './de/home.json';
import main from './de/main.json';
import carrierDetermination from './de/carrierDetermination.json';
import carriers from './de/carriers.json';
import carrierProducts from './de/carrierProducts.json';
import closureJobs from './de/closureJobs.json';
import carrierDeliveryOptions from './de/carrierDeliveryOptions.json';
import labelServiceConfig from './de/labelServiceConfig.json';
import sendItLabelAndClosureConfiguration from './de/sendItLabelAndClosureConfiguration.json';
import warehouse from './de/warehouse.json';
import countries from './de/countries.json';
import audits from './de/audits.json';
import cpt from './de/cpt.json';
import shared from './de/shared.json';
import articleRules from './de/articleRules.json';
import packageMaterialDefinitions from './de/packageMaterialDefinitions.json';
import carrierProductDistributionTimes from './de/carrierProductDistributionTimes.json';
import articleBrickCodeRules from './de/articleBrickCodeRules.json';
import articleServicesRules from './de/articleServicesRules.json';
import backupShippingTypeAddresses from './de/backupShippingTypeAddresses.json';
import additionServices from './de/additionalServices.json';
import carrierProductsAvailability from './de/carrierProductsAvailability.json';
import postalCodeLists from './de/postalCodeLists.json';
import postalCodeWhitelists from './de/postalCodeWhitelists.json';
import trackingEvents from './de/trackingEvents.json';
import trackingEventCodeMappings from './de/trackingEventCodeMappings.json';
import carrierPrices from './de/carrierPrices.json';
import currencyExchangeRates from './de/currencyExchangeRates.json';
import carrierDowntime from './de/carrierDowntime.json';
import activeSessions from './de/activeSessions.json';
import parcelCosts from './de/parcelCosts.json';
import packagingMaterials from './de/packagingMaterials.json';
import packagingMaterialAvailability from './de/packagingMaterialAvailability.json';
import determinationRatio from './de/determinationRatio.json';
import generalDeterminationQuantityLimits from './de/generalDeterminationQuantityLimits.json';
import fallBackCarriers from './de/fallBackCarriers.json';

export const de = [
    home,
    main,
    carrierDetermination,
    carriers,
    carrierProducts,
    closureJobs,
    carrierDeliveryOptions,
    labelServiceConfig,
    shared,
    sendItLabelAndClosureConfiguration,
    warehouse,
    countries,
    cpt,
    articleRules,
    packageMaterialDefinitions,
    carrierProductDistributionTimes,
    articleBrickCodeRules,
    articleServicesRules,
    audits,
    backupShippingTypeAddresses,
    additionServices,
    carrierProductsAvailability,
    postalCodeLists,
    postalCodeWhitelists,
    trackingEvents,
    trackingEventCodeMappings,
    carrierDowntime,
    carrierPrices,
    currencyExchangeRates,
    activeSessions,
    parcelCosts,
    packagingMaterials,
    packagingMaterialAvailability,
    determinationRatio,
    generalDeterminationQuantityLimits,
    fallBackCarriers,
];

