<div class="page px-5" xmlns="http://www.w3.org/1999/html">

    <div class="page__headline" eciTranslate="parcelCosts.title">
        Parcel Cost
    </div>

    <div class="page__filter mb-2">
        <div class="row justify-content-center">
            <div class="col-lg-3">
                <label class="col-form-label me-2"
                       eciTranslate="parcelCosts.table.headers.country">Countries</label>
                <dx-tag-box [dataSource]="countryStore"
                            (onValueChanged)="onFilterChange()"
                            [valueExpr]="'id'"
                            [displayExpr]="'name'"
                            [(value)]="selectedCountries"
                            [searchEnabled]="true">
                </dx-tag-box>
            </div>
            <div class="col-lg-3">
                <label class="col-form-label me-2" eciTranslate="parcelCosts.table.headers.carrier">Carriers</label>
                <dx-tag-box [dataSource]="carrierStore"
                            (onValueChanged)="onFilterChange()"
                            [(value)]="selectedCarriers"
                            [valueExpr]="'id'"
                            [displayExpr]="'name'"
                            [searchEnabled]="true">
                </dx-tag-box>
            </div>
            <div class="col-lg-3">
                <label class="col-form-label me-2"
                       eciTranslate="parcelCosts.table.headers.carrierProduct">Carrier Products</label>
                <dx-tag-box [dataSource]="carrierProductsStore"
                            (onValueChanged)="onFilterChange()"
                            [(value)]="selectedCarrierProducts"
                            [valueExpr]="'id'"
                            [displayExpr]="'name'"
                            [searchEnabled]="true">
                </dx-tag-box>
            </div>
            <div class="col-lg-3">
                <label class="col-form-label me-2"
                       eciTranslate="parcelCosts.table.headers.warehouse">Warehouse</label>
                <dx-tag-box [dataSource]="warehouseStore"
                            (onValueChanged)="onFilterChange()"
                            [(value)]="selectedWarehouses"
                            [valueExpr]="'id'"
                            [displayExpr]="'name'"
                            [searchEnabled]="true">
                </dx-tag-box>
            </div>
        </div>
    </div>

    <div class="row justify-content-center">

        <div class="col-md-12">
            <dx-data-grid (onRowUpdating)="onRowUpdate($event)"
                          (onInitNewRow)="initNewRow($event)"
                          (onToolbarPreparing)="onToolbarPreparing($event)"
                          (onEditorPreparing)="onEditorPreparing($event)"
                          [allowColumnReordering]="true"
                          [allowColumnResizing]="true"
                          [cellHintEnabled]="true"
                          [dataSource]="dataSource"
                          [hoverStateEnabled]="true"
                          [remoteOperations]="{ filtering: true, sorting: true, paging: true }"
                          [rowAlternationEnabled]="true"
                          [showBorders]="true"
                          [showColumnLines]="true"
                          [showRowLines]="true"
                          [errorRowEnabled]="false"
                          [wordWrapEnabled]="true">

                <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showInfo]="true" [showPageSizeSelector]="true" [allowedPageSizes]="allowedPageSizes"
                           [visible]="true"></dxo-pager>
                <dxo-load-panel [enabled]="true"></dxo-load-panel>
                <dxo-editing
                    *eciHasAnyAuthority="Privilege.ReadParcelCost"
                    mode="form"
                    [useIcons]="true"
                    [allowUpdating]="false"
                    [allowDeleting]="false"
                    [allowAdding]="false">
                    <dxo-form [colCount]=1>
                        <dxi-item itemType="group" [colCount]=2>
                            <dxi-item dataField="trackingId"></dxi-item>
                            <dxi-item dataField="packageId"></dxi-item>
                            <dxi-item dataField="countryId"></dxi-item>
                            <dxi-item dataField="carrierId"></dxi-item>
                            <dxi-item dataField="carrierProductId"></dxi-item>
                            <dxi-item dataField="warehouseId"></dxi-item>
                            <dxi-item dataField="shipmentDirection"></dxi-item>
                            <dxi-item dataField="costInLocalCurrency"></dxi-item>
                            <dxi-item dataField="localCurrency"></dxi-item>
                            <dxi-item dataField="costInEuro"></dxi-item>
                            <dxi-item dataField="labelCreationDate"></dxi-item>
                        </dxi-item>

                        <dxi-item itemType="group" [colSpan]=2>
                            <dxi-item alignment="right" [template]="'formButtons'"></dxi-item>
                            <div *dxTemplate="let data of 'formButtons'">
                                <div class="modal-footer">
                                    <dx-check-box [text]="'shared.forms.createAnother' | translate"
                                                  [(value)]="createAnother" class="me-3"></dx-check-box>
                                    <button (click)="cancel()" [disabled]="isSaving" class="btn btn-secondary me-2"
                                            role="button" type="button">
                                        <i aria-hidden="true" class="fas fa-ban me-1"></i>
                                        <span eciTranslate="shared.forms.cancel">Cancel</span>
                                    </button>
                                    <button (click)="save()" [disabled]="isSaving" class="btn btn-primary me-2"
                                            role="button" type="submit">
                                        <i *ngIf="!isSaving" aria-hidden="true" class="fas fa-save me-1"></i>
                                        <i *ngIf="isSaving" aria-hidden="true"
                                           class="fas fa-spinner fa-spin fa-fw me-1"></i> <span
                                        eciTranslate="shared.forms.save">Save</span>
                                    </button>
                                </div>
                            </div>
                        </dxi-item>
                    </dxo-form>
                </dxo-editing>

                <div *dxTemplate="let data of 'gridHeader'">
                    <div class="d-flex align-items-right">
                        <dx-button stylingMode="outlined"
                                   icon="fa-sharp fa-solid fa-filter-circle-xmark"
                                   type="danger"
                                   (onClick)="resetFilter()">
                        </dx-button>
                    </div>
                </div>

                <dxi-column dataField="id"
                            [caption]="'parcelCosts.table.headers.id' | translate"
                            [width]="120"
                            [allowEditing]="false"
                            [visible]="false">
                </dxi-column>

                <dxi-column dataField="trackingId"
                            [caption]="'parcelCosts.table.headers.trackingId' | translate"
                            dataType="string">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>

                <dxi-column dataField="packageId"
                            [caption]="'parcelCosts.table.headers.packageId' | translate"
                            dataType="string">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>

                <dxi-column dataField="countryId"
                            calculateSortValue="countryName"
                            [setCellValue]="resetCarrierProductCell"
                            [caption]="'parcelCosts.table.headers.country' | translate"
                            [calculateFilterExpression]="calculateCountryFilterExpression">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="countryStore"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column dataField="carrierId"
                            [setCellValue]="resetCarrierProductCell"
                            [caption]="'parcelCosts.table.headers.carrier' | translate"
                            [calculateFilterExpression]="calculateCarrierFilterExpression">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="getFilteredCarriers"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column dataField="carrierProductId"
                            [caption]="'parcelCosts.table.headers.carrierProduct' | translate"
                            [setCellValue]="resetCarrierProductCell"
                            [calculateFilterExpression]="calculateCarrierProductFilterExpression">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="getFilteredCarrierProducts"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column dataField="warehouseId"
                            [caption]="'parcelCosts.table.headers.warehouse' | translate"
                            [calculateFilterExpression]="calculateWarehouseFilterExpression">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="getFilteredCarrierProducts"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [caption]="'parcelCosts.table.headers.shipmentDirection' | translate"
                            dataField="shipmentDirection">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="shipmentDirections"
                                displayExpr="name"
                                valueExpr="name">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [allowHeaderFiltering]="false"
                            [caption]="'parcelCosts.table.headers.costInLocalCurrency' | translate"
                            [editorOptions]="{ format: '#0.##', showClearButton: true, step: 0 }"
                            dataType="number"
                            dataField="costInLocalCurrency">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>

                <dxi-column [caption]="'parcelCosts.table.headers.currency' | translate"
                            dataField="localCurrency">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="currencies"
                                displayExpr="value"
                                valueExpr="value">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [allowHeaderFiltering]="false"
                            [caption]="'parcelCosts.table.headers.costInEuro' | translate"
                            [editorOptions]="{ format: '#0.##', showClearButton: true, step: 0 }"
                            dataField="costInEuro"
                            dataType="number">
                </dxi-column>

                <dxi-column [caption]="'parcelCosts.table.headers.labelCreationDate' | translate"
                            [calculateFilterExpression]="calculateLabelCreationDateFilterExpression"
                            dataField="labelCreationDate"
                            dataType="datetime"
                            format="yyyy-MM-dd, HH:mm"
                            [editorOptions]="{ dateSerializationFormat: null }"
                            sortOrder="desc">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>

            </dx-data-grid>
        </div>
    </div>
</div>
