import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CommonService } from '../shared/util/common.service';
import { CarrierPricesService } from './carrier-prices/carrier-prices.service';
import { CurrencyExchangeRatesService } from './currency-exchange-rates/currency-exchange-rates.service';
import { ParcelCostService } from './parcel-cost/parcel-cost.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
  ],
  providers: [
      CarrierPricesService,
      CurrencyExchangeRatesService,
      ParcelCostService,
      CommonService,
  ],
})
export class ParcelCostsModule { }
